import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
  ColumnFiltersState,
  ColumnOrderState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table'

export interface TableFiltersState {
  columnVisibility: VisibilityState
  columnOrder: ColumnOrderState
  columnFilters: ColumnFiltersState
  globalFilter: string
  sorting: SortingState
}

const initialState: TableFiltersState = {
  columnVisibility: {},
  columnOrder: [],
  columnFilters: [],
  globalFilter: '',
  sorting: [],
}

export const tableFiltersSlice = createSlice({
  name: 'tableFilters',
  initialState,
  reducers: {
    setTableFiltersState: (
      state: TableFiltersState,
      action: PayloadAction<TableFiltersState>
    ) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { setTableFiltersState } = tableFiltersSlice.actions

export default tableFiltersSlice.reducer

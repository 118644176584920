// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../constants'
import type {
  Auth0InviteMemberResponse,
  Auth0VerificationEmailResponse,
  BankAccountCreateCommand,
  BankAccountDto,
  BrokerPerformanceDto,
  CommandModel,
  CountryDto,
  CreateAmlForBusinessCommand,
  CreateFundDepositCommand,
  CreateKycCaseCommand,
  CreateQuestionnaireCommand,
  CurrencyCreateCommand,
  CurrencyDto,
  CurrencyToOffRampDto,
  DepositDto,
  ExchangeRateAllDto,
  ExchangeRatePairDto,
  FeeStructureDto,
  FundPerformanceDto,
  InvestorFundPerformanceDto,
  InviteCommand,
  KycDetailsDto,
  KycDocumentDto,
  KycQuestionnaireCreateCommand,
  KycQuestionnaireDto,
  LinkUltimateBeneficialOwnersCommand,
  NetworkCreateCommand,
  NetworkDto,
  ShuftiProApiAmlForBusinessResponse,
  ShuftiProApiKybResponse,
  SuspendAccountCommand,
  SwapCreateCommand,
  SwapDto,
  SwapInvoiceDto,
  SwapUpdateCommand,
  SwapUpdateStatusCommand,
  TransferActivityDto,
  TransferDto,
  TransferQuotationDto,
  UltimateBeneficialOwnerDto,
  UpdateCommand,
  UpdateCurrencyCommandModel,
  UpdateFundPerformanceCommand,
  UpdateKycStatusCommand,
  UserCreateCommand,
  UserDto,
  WalletCreateCommand,
  WalletDto,
} from '../../types/coreApi-types'
import type { RootState } from '../store'

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, api) => {
    const state = api.getState() as RootState

    const token = state.session.token
    const otacToken = state.session.otacToken

    headers.set('Authorization', `Bearer ${token}`)
    if (otacToken) {
      headers.set('OneTimeAccessToken', otacToken)
    }
    return headers
  },
})

// Define a service using a base URL and expected endpoints
export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery,
  tagTypes: [
    'User',
    'Users',
    'Wallets',
    'Swaps',
    'Members',
    'BankAccounts',
    'Currencies',
    'Networks',
    'KycDocuments',
    'KycQuestionnaire',
    'UBOS',
    'OfframpDetails',
    'BrokerPerformance',
    'Performance',
    'FundPerformance',
    'InvestorFundPerformance',
    'FundDeposits',
  ],
  endpoints: builder => ({
    createAccount: builder.mutation<UserDto, UserCreateCommand>({
      query: body => ({
        url: '/users',
        method: 'POST',
        body,
      }),
    }),
    login: builder.mutation<
      {
        id: string
        jwt: string
      },
      { username: string; password: string }
    >({
      query: body => ({
        url: '/login',
        method: 'POST',
        body,
      }),
    }),
    getUser: builder.query<UserDto, string>({
      query: userId => `/users/${userId}`,
      providesTags: ['User'],
    }),
    updateUser: builder.mutation<void, UpdateCommand>({
      query: body => ({
        url: '/users',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['User', 'Users'],
    }),
    createSwap: builder.mutation<SwapDto, SwapCreateCommand>({
      query: body => ({
        url: '/swaps',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Swaps'],
    }),
    getSwaps: builder.query<SwapDto[], null>({
      query: () => `/swaps`,
      providesTags: ['Swaps'],
    }),
    getUsers: builder.query<UserDto[], null>({
      query: () => `/users`,
      providesTags: ['Users'],
    }),
    createWallet: builder.mutation<WalletDto, WalletCreateCommand>({
      query: body => ({
        url: '/wallets',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Wallets'],
    }),
    getExchangeRatePair: builder.query<
      ExchangeRatePairDto,
      { currencyFromIsoCode: string; currencyToIsoCode: string }
    >({
      query: ({ currencyFromIsoCode, currencyToIsoCode }) => ({
        url: `/exchangeRates/${currencyFromIsoCode}/${currencyToIsoCode}`,
      }),
    }),
    getExchangeRates: builder.query<
      ExchangeRateAllDto,
      { currencyIsoCode: string }
    >({
      query: ({ currencyIsoCode }) => ({
        url: `/exchangeRates/${currencyIsoCode}`,
      }),
    }),
    getWallets: builder.query<WalletDto[], null>({
      query: () => `/wallets`,
      providesTags: ['Wallets'],
    }),
    setSwapStatus: builder.mutation<SwapDto, SwapUpdateStatusCommand>({
      query: body => ({
        url: '/swaps/setSwapStatus',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Swaps'],
    }),
    getCurrencies: builder.query<CurrencyDto[], void>({
      query: () => ({
        url: '/currencies',
      }),
      providesTags: ['Currencies'],
    }),
    getBrokerPerformance: builder.query<
      BrokerPerformanceDto,
      { from: string; to: string; currencyId: string }
    >({
      query: params => ({
        url: `/broker/performance?from=${params.from}&to=${params.to}&currencyId=${params.currencyId}`,
      }),
      providesTags: ['BrokerPerformance'],
    }),
    getNetworks: builder.query<NetworkDto[], null>({
      query: () => ({
        url: '/networks',
      }),
      providesTags: ['Networks'],
    }),
    resendVerificationEmail: builder.mutation<
      Auth0VerificationEmailResponse,
      null
    >({
      query: () => ({
        url: '/users/verification-email',
        method: 'POST',
        body: {},
      }),
    }),
    inviteMember: builder.mutation<Auth0InviteMemberResponse, InviteCommand>({
      query: body => ({
        url: '/members',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Members', 'User'],
    }),
    getMembers: builder.query<UserDto[], null>({
      query: () => ({
        url: '/members',
      }),
      providesTags: ['Members'],
    }),
    getQuotation: builder.query<
      TransferQuotationDto,
      {
        amountIn?: number
        amountOut?: number
        currencyInId: string
        currencyOutId: string
      }
    >({
      query: body => {
        let amountParams = ''
        if (body.amountIn) {
          amountParams = `amountIn=${body.amountIn}`
        } else {
          amountParams = `amountOut=${body.amountOut}`
        }

        return {
          url: `/swaps/quotation?${amountParams}&currencyInId=${body.currencyInId}&currencyOutId=${body.currencyOutId}`,
        }
      },
    }),
    acceptBetaDisclaimer: builder.mutation<UserDto, null>({
      query: () => ({
        url: '/users/accept-beta-disclaimer',
        method: 'POST',
      }),
    }),
    getSwapActivities: builder.query<TransferActivityDto[], string>({
      query: swapId => ({
        url: `/swaps/${swapId}/activities`,
      }),
    }),
    updateKycStatus: builder.mutation<UserDto, UpdateKycStatusCommand>({
      query: body => ({
        url: `/users/${body.userId}/kyc`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Users', 'User'],
    }),
    deleteWallet: builder.mutation<WalletDto, string>({
      query: walletId => ({
        url: `/wallets/${walletId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Wallets'],
    }),
    getBankAccounts: builder.query<BankAccountDto[], null>({
      query: () => `/bankAccounts`,
      providesTags: ['BankAccounts'],
    }),
    createBankAccount: builder.mutation<
      BankAccountDto,
      BankAccountCreateCommand
    >({
      query: body => ({
        url: '/bankAccounts',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BankAccounts'],
    }),
    deleteBankAccount: builder.mutation<BankAccountDto, string>({
      query: bankAccountId => ({
        url: `/bankAccounts/${bankAccountId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BankAccounts'],
    }),
    uploadKycDocument: builder.mutation<any, FormData>({
      query: body => ({
        url: `/kycDocuments/upload`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['KycDocuments'],
    }),
    getKycDocuments: builder.query<KycDocumentDto[], void>({
      query: () => ({
        url: `/kycDocuments`,
        method: 'GET',
      }),
      providesTags: ['KycDocuments'],
    }),
    createKycQuestionnaire: builder.mutation<
      KycQuestionnaireDto,
      KycQuestionnaireCreateCommand
    >({
      query: body => ({
        url: `/kycQuestionnaires`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['KycQuestionnaire'],
    }),
    getKycQuestionnaires: builder.query<KycQuestionnaireDto[], void>({
      query: () => ({
        url: `/kycQuestionnaires`,
        method: 'GET',
      }),
      providesTags: ['KycQuestionnaire'],
    }),
    getKycQuestionnairesById: builder.query<
      KycQuestionnaireDto,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/users/${id}/kycQuestionnaire`,
        method: 'GET',
      }),
    }),
    getKycDocumentsById: builder.query<KycDocumentDto[], { id: string }>({
      query: ({ id }) => ({
        url: `/users/${id}/kycDocuments`,
        method: 'GET',
      }),
    }),
    getCountries: builder.query<CountryDto[], void>({
      query: () => ({
        url: `/countries`,
      }),
    }),
    getUltimateBeneficialOwners: builder.query<
      UltimateBeneficialOwnerDto[],
      void | null
    >({
      query: () => ({
        url: `/users/getUltimateBeneficialOwners`,
        method: 'POST',
      }),
      providesTags: ['UBOS'],
    }),
    linkUltimateBeneficialOwners: builder.mutation<
      UserDto[],
      LinkUltimateBeneficialOwnersCommand
    >({
      query: body => ({
        url: `/users/linkUltimateBeneficialOwners`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Members', 'UBOS', 'User'],
    }),
    createKycCase: builder.mutation<KycDetailsDto, CreateKycCaseCommand>({
      query: body => ({
        url: `/users/kyc`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Members', 'User'],
    }),
    createQuestionnaire: builder.mutation<
      KycDetailsDto,
      CreateQuestionnaireCommand
    >({
      query: body => ({
        url: `/users/questionnaire`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Members', 'User'],
    }),
    createAmlForBusiness: builder.mutation<
      ShuftiProApiAmlForBusinessResponse,
      CreateAmlForBusinessCommand
    >({
      query: body => ({
        url: `/users/aml`,
        method: 'POST',
        body,
      }),
    }),
    createKyBCase: builder.mutation<ShuftiProApiKybResponse, {}>({
      query: body => ({
        url: `/users/kyb`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User', 'UBOS'],
    }),
    getAllBankAccountsByUserId: builder.query<
      BankAccountDto[],
      { userId: string }
    >({
      query: ({ userId }) => ({
        url: `/users/${userId}/bank-accounts`,
        method: 'GET',
      }),
      providesTags: ['BankAccounts'],
    }),
    getAllWalletsByUserId: builder.query<WalletDto[], { userId: string }>({
      query: ({ userId }) => ({
        url: `/users/${userId}/wallets`,
        method: 'GET',
      }),
      providesTags: ['Wallets'],
    }),
    getAllMembersByOrganizationId: builder.query<
      UserDto[],
      { organizationId: string }
    >({
      query: ({ organizationId }) => ({
        url: `/organizations/${organizationId}/members`,
        method: 'GET',
      }),
      providesTags: ['Members'],
    }),
    getAllTransfers: builder.query<TransferDto[], { userId: string }>({
      query: ({ userId }) => ({
        url: `/users/${userId}/transfers`,
        method: 'GET',
      }),
      providesTags: [],
    }),
    getAllTransferActivity: builder.query<
      TransferActivityDto[],
      { userId: string }
    >({
      query: ({ userId }) => ({
        url: `/users/${userId}/transfer-activities`,
        method: 'GET',
      }),
      providesTags: [],
    }),
    getAllSwapsById: builder.query<SwapDto[], { userId: string }>({
      query: ({ userId }) => ({
        url: `/users/${userId}/swaps`,
        method: 'GET',
      }),
      providesTags: ['Swaps'],
    }),
    getFeeStructure: builder.query<FeeStructureDto, { userId: string }>({
      query: ({ userId }) => ({
        url: `/users/${userId}/feeStructure`,
        method: 'GET',
      }),
      // providesTags: [''],
    }),
    getOfframpDetails: builder.query<CurrencyToOffRampDto[], null>({
      query: () => ({
        url: `/currencies/offramp-details`,
        method: 'GET',
      }),
      providesTags: ['OfframpDetails'],
    }),
    updateCurrency: builder.mutation<
      CurrencyDto,
      { id: string; body: UpdateCurrencyCommandModel }
    >({
      query: ({ id, body }) => ({
        url: `/currencies/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Currencies', 'OfframpDetails'],
    }),
    updateSwap: builder.mutation<SwapDto, SwapUpdateCommand>({
      query: body => ({
        url: `/swaps`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Swaps'],
    }),
    toggleFavourite: builder.mutation<
      {},
      { currencyId: string; isFavourite: boolean }
    >({
      query: body => ({
        url: `/currencies/favourite`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    createNetwork: builder.mutation<NetworkDto, NetworkCreateCommand>({
      query: body => ({
        url: `/networks`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Networks', 'OfframpDetails'],
    }),
    uploadNetworkIcon: builder.mutation<
      NetworkDto,
      { networkId: string; formData: FormData }
    >({
      query: ({ networkId, formData }) => ({
        url: `/networks/upload/${networkId}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Networks', 'OfframpDetails'],
    }),
    createCurrency: builder.mutation<CurrencyDto, CurrencyCreateCommand>({
      query: body => ({
        url: `/currencies`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Currencies', 'OfframpDetails'],
    }),
    uploadCurrencyIcon: builder.mutation<
      CurrencyDto,
      { currencyId: string; formData: FormData }
    >({
      query: ({ currencyId, formData }) => ({
        url: `/currencies/upload/${currencyId}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Currencies', 'OfframpDetails'],
    }),
    otacGetSwapInvoiceDetails: builder.query<SwapInvoiceDto, string>({
      query: id => ({
        url: `/otac/swaps/${id}/invoice-details`,
        method: 'GET',
      }),
    }),
    getExchangeRateRawCmcInEur: builder.query<
      ExchangeRatePairDto,
      { currencyFromIsoCode: string }
    >({
      query: ({ currencyFromIsoCode }) => ({
        url: `/exchangeRates/raw-cmc/${currencyFromIsoCode}`,
      }),
    }),
    suspendAccount: builder.mutation<UserDto, SuspendAccountCommand>({
      query: body => ({
        url: `/users/${body.userId}/suspend`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Users', 'User'],
    }),
    //Admin
    getFundPerformance: builder.query<
      FundPerformanceDto[],
      { start: string; end: string } | null
    >({
      query: payload => ({
        url: payload
          ? `/fund/performance?start=${payload.start}&end=${payload.end}`
          : `/fund/performance`,
        method: 'GET',
      }),
      providesTags: ['FundPerformance'],
    }),
    updateFundPerformance: builder.mutation<any, UpdateFundPerformanceCommand>({
      query: body => ({
        url: `/fund/performance`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['FundPerformance'],
    }),
    createFundDeposit: builder.mutation<any, CreateFundDepositCommand>({
      query: body => ({
        url: `/fund/deposits`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FundDeposits'],
    }),
    updateFundDeposit: builder.mutation<
      any,
      CommandModel & { depositId: string }
    >({
      query: ({ depositId, amount, transferStatus, transferredAt }) => ({
        url: `/fund/deposits/${depositId}`,
        method: 'PATCH',
        body: {
          amount,
          transferStatus,
          transferredAt,
        },
      }),
      invalidatesTags: ['FundDeposits'],
    }),
    //Investor
    getInvestorFundPerformance: builder.query<
      InvestorFundPerformanceDto[],
      { start: string; end: string; convertToEur: boolean }
    >({
      query: ({ start, end, convertToEur }) => ({
        url: `/users/fund-performance?start=${start}&end=${end}&convertToEur=${convertToEur}`,
        method: 'GET',
      }),
      providesTags: ['InvestorFundPerformance'],
    }),
    getFundDeposits: builder.query<DepositDto[], { investorId: string }>({
      query: ({ investorId }) => ({
        url: `/fund/deposits/${investorId}`,
        method: 'GET',
      }),
      providesTags: ['FundDeposits'],
    }),
    deleteFundDeposit: builder.mutation<DepositDto, string>({
      query: fundDepositId => ({
        url: `/fund/deposits/${fundDepositId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FundDeposits'],
    }),
  }),
})

export const {
  useUpdateFundDepositMutation,
  useDeleteFundDepositMutation,
  useGetFundDepositsQuery,
  useCreateFundDepositMutation,
  useLazyGetInvestorFundPerformanceQuery,
  useGetInvestorFundPerformanceQuery,
  useUpdateFundPerformanceMutation,
  useGetFundPerformanceQuery,
  useCreateAccountMutation,
  useLoginMutation,
  useCreateSwapMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUsersQuery,
  useCreateWalletMutation,
  useGetWalletsQuery,
  useGetExchangeRatesQuery,
  useGetSwapsQuery,
  useSetSwapStatusMutation,
  useGetNetworksQuery,
  useGetCurrenciesQuery,
  useGetBrokerPerformanceQuery,
  useGetExchangeRatePairQuery,
  useLazyGetExchangeRatePairQuery,
  useResendVerificationEmailMutation,
  useInviteMemberMutation,
  useGetMembersQuery,
  useGetQuotationQuery,
  useAcceptBetaDisclaimerMutation,
  useGetSwapActivitiesQuery,
  useUpdateKycStatusMutation,
  useDeleteWalletMutation,
  useGetBankAccountsQuery,
  useCreateBankAccountMutation,
  useDeleteBankAccountMutation,
  useUploadKycDocumentMutation,
  useGetKycDocumentsQuery,
  useCreateKycQuestionnaireMutation,
  useGetKycQuestionnairesQuery,
  useGetKycQuestionnairesByIdQuery,
  useGetKycDocumentsByIdQuery,
  useGetCountriesQuery,
  useGetUltimateBeneficialOwnersQuery,
  useLinkUltimateBeneficialOwnersMutation,
  useCreateKycCaseMutation,
  useCreateQuestionnaireMutation,
  useCreateAmlForBusinessMutation,
  useCreateKyBCaseMutation,
  useGetAllBankAccountsByUserIdQuery,
  useLazyGetAllBankAccountsByUserIdQuery,
  useGetAllWalletsByUserIdQuery,
  useLazyGetAllWalletsByUserIdQuery,
  useGetAllMembersByOrganizationIdQuery,
  useLazyGetAllMembersByOrganizationIdQuery,
  useGetAllTransfersQuery,
  useLazyGetAllTransfersQuery,
  useGetAllTransferActivityQuery,
  useLazyGetAllTransferActivityQuery,
  useGetAllSwapsByIdQuery,
  useLazyGetAllSwapsByIdQuery,
  useGetFeeStructureQuery,
  useUpdateUserMutation,
  useGetOfframpDetailsQuery,
  useUpdateCurrencyMutation,
  useUpdateSwapMutation,
  useToggleFavouriteMutation,
  useCreateNetworkMutation,
  useUploadNetworkIconMutation,
  useCreateCurrencyMutation,
  useUploadCurrencyIconMutation,
  useOtacGetSwapInvoiceDetailsQuery,
  useLazyGetExchangeRateRawCmcInEurQuery,
  useSuspendAccountMutation,
} = coreApi

import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import {
  faAngleLeft,
  faAngleRight,
  faCheck,
  faChevronDown,
  faChevronUp,
  faEllipsis,
  faFont,
  faHashtag,
  faList,
  faLock,
  faMagnifyingGlass,
  faRefresh,
  faSliders,
  faTableCellsLarge,
  faTableColumns,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Drawer, Menu, Popover, Skeleton, Tooltip } from '@mantine/core'
import { DatePickerInput, type DateValue } from '@mantine/dates'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { type RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import {
  type CellContext,
  type Column,
  type ColumnDef,
  type ColumnFiltersState,
  type ColumnOrderState,
  type FilterFn,
  type FilterFnOption,
  type SortingFnOption,
  type SortingState,
  type Table,
  type VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import debounce from 'lodash/debounce'
import moment from 'moment'
import type React from 'react'
import {
  type Dispatch,
  type InputHTMLAttributes,
  type SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTableFiltersState } from '../../redux/slices/tableFiltersSlice'
import type { RootState } from '../../redux/store'
import { DateFormatOption, formatUtcDate } from '../../utils/formatters'
import roundedNumberToString from '../../utils/roundedNumberToString'
import type { PillColor } from './Pill'

export type SchemaType = 'date' | 'string' | 'number' | 'status'
export type TableType = 'table' | 'cards'

export type MenuAction<T> = {
  title: string
  onClick: (value: T) => void
  hidden: (value: T) => boolean
  disabled?: (value: T) => boolean
}

export type SchemaItem<T> = {
  cardTitle?: boolean
  header: string
  accessorKey: string
  priority: number
  type: SchemaType
  permanent?: boolean
  newlyAdded?: string
  suffix?: string
  initialSorted?: boolean
  cellValueWrapper?: (
    value: string,
    originalValue: string,
    row?: T
  ) => JSX.Element
  pillColor?: (arg: string) => PillColor
}

interface InfoodTableProps<T> {
  defaultData: T[]
  onClick: (value: T) => void
  isLoading?: boolean
  schema: SchemaItem<T>[]
  menuActions: MenuAction<T>[]
  emptyDataRender: JSX.Element
}

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
    isWithinRange: FilterFn<unknown>
    notIncluded: FilterFn<unknown>
    isBetween: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  if (row.original['isLoading']) return true

  const itemRank = rankItem(row.getValue(columnId), value, {
    threshold: 1,
  })

  addMeta({
    itemRank,
  })

  return itemRank.passed
}

const isWithinRange: FilterFn<any> = (row, columnId, value) => {
  if (row.original['isLoading']) return true
  const rowValue = row.getValue(columnId) as string
  const date = new Date(rowValue)

  const start = moment(value[0]).startOf('d').toDate()
  const end = moment(value[1]).endOf('d').toDate()
  if ((start || end) && !date) return false
  if (start && !end) {
    return date.getTime() >= start.getTime()
  } else if (!start && end) {
    return date.getTime() <= end.getTime()
  } else if (start && end) {
    return date.getTime() >= start.getTime() && date.getTime() <= end.getTime()
  } else return true
}

const notIncluded: FilterFn<any> = (row, columnId, value) => {
  const rowValue = row.getValue(columnId) as string
  if (value.includes(rowValue)) return false
  return true
}

const isBetween: FilterFn<any> = (row, columnId, value) => {
  if (row.original['isLoading']) return true
  const rowValue = row.getValue(columnId) as string
  const number = Number(rowValue)
  const minNumber = Number(value[0])
  const maxNumber = Number(value[1])

  if (!maxNumber && !minNumber) return true
  if (!maxNumber && number > minNumber) return true
  if (!minNumber && (number < maxNumber || !number)) return true
  if (number < maxNumber && number > minNumber) return true
  return false
}

const InfoodTable = <T extends object>({
  isLoading,
  onClick,
  defaultData,
  schema,
  menuActions,
  emptyDataRender,
}: InfoodTableProps<T>) => {
  const defaultColumns = useMemo<ColumnDef<T, any>[]>(
    () => [
      ...schema.map(item => {
        const accessorKey = item.accessorKey
        let cell = (info: CellContext<T, unknown>) => info.getValue()
        let filterFn: FilterFnOption<T> | undefined = undefined
        let sortingFn: SortingFnOption<T> | undefined = undefined
        let enableGlobalFilter = false
        const sortDescFirst = false

        switch (item.type) {
          case 'date':
            cell = info =>
              info.getValue()
                ? formatUtcDate(
                    info.getValue() as string,
                    DateFormatOption.DATE
                  )
                : ''
            filterFn = 'isWithinRange'
            break
          case 'string':
            filterFn = 'fuzzy'
            enableGlobalFilter = true
            break
          case 'number':
            filterFn = 'isBetween'
            sortingFn = (rowA, rowB, columnId) => {
              const numA = rowA.getValue(columnId) ?? (0 as number)
              const numB = rowB.getValue(columnId) ?? (0 as number)

              return numB < numA ? 1 : numB > numA ? -1 : 0
            }
            cell = info =>
              info.getValue()
                ? roundedNumberToString(info.getValue() as number, 2)
                : ''
            break
          case 'status':
            filterFn = 'notIncluded'
            break

          default:
            break
        }
        const oldCell = cell
        if (item.suffix) {
          cell = info =>
            info.getValue() ? (oldCell(info) as string) + item.suffix : ''
        }

        if (item.cellValueWrapper) {
          const initialCell = cell
          cell = info =>
            item.cellValueWrapper
              ? item.cellValueWrapper(
                  initialCell(info) as string,
                  oldCell(info) as string,
                  info.row.original
                )
              : oldCell
        }

        if (cell) {
          const finalCell = cell
          cell = info => {
            const original = info.row.original as Record<string, T>
            return original['isLoading'] && !finalCell(info) ? (
              <Skeleton height={23} radius={15} width={'70%'} />
            ) : (
              finalCell(info)
            )
          }
        }
        if (sortingFn) {
          return {
            header: item.header,
            accessorKey: accessorKey,
            cell: cell,
            filterFn: filterFn,
            enableGlobalFilter: enableGlobalFilter,
            sortingFn: sortingFn,
            sortDescFirst: sortDescFirst,
          }
        }

        return {
          header: item.header,
          accessorKey: accessorKey,
          cell: cell,
          filterFn: filterFn,
          enableGlobalFilter: enableGlobalFilter,
          sortDescFirst: sortDescFirst,
        }
      }),
    ],
    []
  )

  const [data, setData] = useState(() => [...defaultData])

  useEffect(() => {
    setData(() => [...defaultData])
  }, [defaultData])

  const smallScreen = useMediaQuery('(max-width: 1024px)')

  const [tableType, setTableType] = useState<TableType>(
    smallScreen ? 'cards' : 'table'
  )

  useEffect(() => {
    if (smallScreen) setTableType('cards')
  }, [smallScreen])

  const tableFiltersState = useSelector(
    (state: RootState) => state.tableFilters
  )

  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    tableFiltersState.columnVisibility ?? {}
  )

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    tableFiltersState.columnOrder.length > 0
      ? tableFiltersState.columnOrder
      : [
          ...schema
            .sort((a, b) => a.priority - b.priority)
            .map(item => item.accessorKey),
        ]
  )
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    tableFiltersState.columnFilters.length > 0
      ? tableFiltersState.columnFilters
      : []
  )
  const [globalFilter, setGlobalFilter] = useState(
    tableFiltersState.globalFilter ?? ''
  )
  const [sorting, setSorting] = useState<SortingState>(
    tableFiltersState.sorting.length > 0
      ? tableFiltersState.sorting
      : [
          ...(schema.find(item => item.initialSorted)
            ? [
                {
                  id: schema.find(item => item.initialSorted)!.accessorKey,
                  desc: true,
                },
              ]
            : []),
        ]
  )

  // Save state with redux. Make more generic in the future.
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      setTableFiltersState({
        columnVisibility,
        columnOrder,
        columnFilters,
        globalFilter,
        sorting,
      })
    )
  }, [columnVisibility, columnOrder, columnFilters, globalFilter, sorting])

  const initialPageSize = 15

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
      isWithinRange: isWithinRange,
      notIncluded: notIncluded,
      isBetween: isBetween,
    },
    state: {
      columnVisibility,
      globalFilter,
      columnFilters,
      sorting,
      columnOrder,
    },
    initialState: {
      pagination: { pageSize: initialPageSize },
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: fuzzyFilter,
    autoResetPageIndex: false,
  })

  useEffect(() => {
    if (table.getState().pagination.pageIndex + 1 > table.getPageCount()) {
      table.setPageIndex(table.getPageCount() - 1)
    }
  }, [table.getPageCount()])

  useEffect(() => {
    const allHideableColumnIds = table
      .getAllColumns()
      .filter(column => column.getCanHide())
      .map(column => column.id)
    const initialColumnIds: string[] = schema
      .sort((a, b) => a.priority - b.priority)
      .slice(0, maxVisibleColumns)
      .map(item => item.accessorKey)
    const initialColumnVisibility: VisibilityState =
      allHideableColumnIds.reduce((visibilityState, columnId) => {
        visibilityState[columnId] = initialColumnIds.includes(columnId)
        return visibilityState
      }, {} as VisibilityState)

    setColumnVisibility(initialColumnVisibility)
  }, [])

  const enabledColumns = Object.entries(columnVisibility).filter(
    item => item[1] === true
  )

  const [maxVisibleColumns, setMaxVisibleColumns] = useState(5)

  const tableWidthRef = useRef<HTMLDivElement>(null)
  const [tableWidth, setTableWidth] = useState(0)

  useEffect(() => {
    if (!tableWidthRef.current) {
      return
    }

    const resizeObserver = new ResizeObserver(
      debounce(() => {
        if (
          tableWidthRef.current &&
          tableWidthRef.current.offsetWidth !== tableWidth
        ) {
          setTableWidth(tableWidthRef.current.offsetWidth)
        }
      }, 100)
    )

    resizeObserver.observe(tableWidthRef.current)

    return function cleanup() {
      resizeObserver.disconnect()
    }
  }, [tableWidthRef.current])

  const maxColumnWidth = 250
  const maxCardWidth = 350
  const cardRows = 3

  useEffect(() => {
    if (tableWidth === 0) return
    const newColumns = Math.floor(
      tableWidth / (tableType === 'table' ? maxColumnWidth : maxCardWidth)
    )
    setMaxVisibleColumns(newColumns)

    if (newColumns === maxVisibleColumns) return

    if (tableType === 'cards') table.setPageSize(newColumns * cardRows)

    const allHideableColumnIds = table
      .getAllColumns()
      .filter(column => column.getCanHide())
      .map(column => column.id)

    const columnIds: string[] = schema
      .sort((a, b) => a.priority - b.priority)
      .slice(0, tableType === 'table' ? newColumns : 5)
      .map(item => item.accessorKey)

    const columnVisibility: VisibilityState = allHideableColumnIds.reduce(
      (visibilityState, columnId) => {
        visibilityState[columnId] = columnIds.includes(columnId)
        return visibilityState
      },
      {} as VisibilityState
    )

    setColumnVisibility(columnVisibility)
  }, [tableWidth, tableWidthRef.current])

  useEffect(() => {
    if (tableWidth === 0) return
    const newColumns = Math.floor(
      tableWidth / (tableType === 'table' ? maxColumnWidth : maxCardWidth)
    )

    if (tableType === 'table') table.setPageSize(15)
    if (tableType === 'cards') table.setPageSize(newColumns * cardRows)

    const allHideableColumnIds = table
      .getAllColumns()
      .filter(column => column.getCanHide())
      .map(column => column.id)

    const columnIds: string[] = schema
      .sort((a, b) => a.priority - b.priority)
      .slice(0, tableType === 'table' ? newColumns : 5)
      .map(item => item.accessorKey)

    const columnVisibility: VisibilityState = allHideableColumnIds.reduce(
      (visibilityState, columnId) => {
        visibilityState[columnId] = columnIds.includes(columnId)
        return visibilityState
      },
      {} as VisibilityState
    )

    setColumnVisibility(columnVisibility)
  }, [tableType])

  const searchRef = useRef<HTMLInputElement>(null)

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.metaKey && (event.key === 'K' || event.key === 'k')) {
      event.preventDefault()
      searchRef.current?.focus()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  const [opened, { open, close }] = useDisclosure(false)

  return (
    <div className="w-full text-sm font-medium">
      {smallScreen ? (
        <>
          <div className="w-full flex justify-between gap-2 h-[42px] ">
            <DebouncedInput
              ref={searchRef}
              value={globalFilter ?? ''}
              onChange={value => setGlobalFilter(String(value))}
              className="p-2 font-lg border border-block rounded-lg bg-brand-gray-50 border-brand-gray-300 border-solid w-full"
              containerClassName="w-full !rounded-full h-full border-brand-gray-300"
              placeholder="Search"
              renderLeft={
                <div
                  className={`top-0 h-full flex items-center font-semi text-base`}
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="text-brand-text-description text-[13px]"
                  />
                </div>
              }
            />
            <button
              onClick={open}
              className="border rounded-full bg-brand-gray-50 border-brand-gray-300 border-solid min-w-[42px] w-[42px] flex justify-center items-center"
            >
              <FontAwesomeIcon icon={faSliders} className="text-[15px]" />
            </button>
          </div>
          <Drawer
            classNames={{ body: '!p-0' }}
            styles={{ body: { height: 'calc(100% - 74px)' } }}
            withCloseButton={false}
            position="right"
            opened={opened}
            onClose={close}
          >
            <Drawer.Header>
              <Drawer.Title>
                <SelectColumnMenu
                  forDrawer
                  enabledColumns={enabledColumns}
                  maxVisibleColumns={maxVisibleColumns}
                  schema={schema}
                  table={table}
                  tableType={tableType}
                />
              </Drawer.Title>
              <Drawer.CloseButton className="!bg-brand-gray-100 !rounded-full !w-[42px] !h-[42px] !p-2" />
            </Drawer.Header>
            <FilterDrawerContent
              table={table}
              schema={schema}
              columnVisibility={columnVisibility}
              onClose={close}
            />
          </Drawer>
        </>
      ) : (
        <div className="flex w-full justify-between items-end gap-2 flex-wrap">
          <div className="flex gap-1.5 h-[40px]">
            <FilterButtons
              table={table}
              schema={schema}
              columnVisibility={columnVisibility}
            />
          </div>

          <div className="flex gap-1.5 h-[40px]">
            <SelectColumnMenu
              enabledColumns={enabledColumns}
              maxVisibleColumns={maxVisibleColumns}
              schema={schema}
              table={table}
              tableType={tableType}
            />

            <DebouncedInput
              ref={searchRef}
              value={globalFilter ?? ''}
              onChange={value => setGlobalFilter(String(value))}
              className="p-2 font-lg border border-block rounded-lg bg-brand-gray-50 border-brand-gray-300 border-solid"
              containerClassName="border-brand-gray-300"
              placeholder="Search"
              renderLeft={
                <div
                  className={`top-0 h-full flex items-center font-semi text-base`}
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="text-brand-text-description text-[13px]"
                  />
                </div>
              }
              renderRight={
                <div
                  className={`p-1 bg-brand-gray-200 text-brand-gray-700 rounded text-xs px-2 `}
                >
                  ⌘ + K
                </div>
              }
            />

            <TableTypeButtons
              tableType={tableType}
              setTableType={setTableType}
            />
          </div>
        </div>
      )}

      <div className="h-6 " />
      {tableType === 'table' && (
        <div
          ref={tableWidthRef}
          className="border border-solid border-gray-300 rounded-xl overflow-hidden  bg-white"
          // h-[815px]
        >
          <TableHeader table={table} maxVisibleColumns={maxVisibleColumns} />

          <div className="h-full">
            {isLoading ? (
              <RowsLoading
                table={table}
                maxVisibleColumns={maxVisibleColumns}
              />
            ) : table.getRowModel().rows.length > 0 ? (
              <Rows
                table={table}
                onClick={onClick}
                schema={schema}
                menuActions={menuActions}
                maxVisibleColumns={maxVisibleColumns}
              />
            ) : defaultData.length === 0 ? (
              <div className="w-full h-full flex flex-col items-center justify-center">
                {emptyDataRender}
              </div>
            ) : (
              <div className="w-full h-full flex flex-col items-center justify-center py-10">
                <FiltersNoMatches table={table} />
              </div>
            )}
          </div>
        </div>
      )}
      {tableType === 'cards' && (
        <>
          <div
            ref={tableWidthRef}
            className={`grid ${
              'grid-cols-' + maxVisibleColumns
            } w-full gap-3 h-[750px] grid-rows-3`}
          >
            {isLoading ? (
              <CardsLoading table={table} />
            ) : table.getRowModel().rows.length > 0 ? (
              <Cards
                table={table}
                onClick={onClick}
                schema={schema}
                menuActions={menuActions}
              />
            ) : defaultData.length === 0 ? (
              <div
                className={`w-full h-[400px] flex flex-col items-center justify-center col-span-full`}
              >
                {emptyDataRender}
              </div>
            ) : (
              <div
                className={`w-full h-[400px] flex flex-col items-center justify-center col-span-full `}
              >
                <FiltersNoMatches table={table} />
              </div>
            )}
          </div>
        </>
      )}
      <div className="h-10" />
      {!isLoading &&
        defaultData.length > 0 &&
        table.getRowModel().rows.length > 0 && <Pagination table={table} />}
    </div>
  )
}

export default InfoodTable

const DebouncedInput = forwardRef<
  HTMLInputElement,
  {
    value?: string | number
    onChange: (value?: string | number) => void
    debounce?: number
    renderLeft?: JSX.Element
    renderRight?: JSX.Element
    containerClassName?: string
  } & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>
>(
  (
    {
      value: initialValue,
      onChange,
      debounce = 500,
      renderLeft,
      renderRight,
      containerClassName,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(initialValue)

    const [first, setFirst] = useState(true)

    useEffect(() => {
      if (first) {
        setFirst(false)
        return
      }
      setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
      if (first) {
        setFirst(false)
        return
      }
      const timeout = setTimeout(() => {
        onChange(value)
      }, debounce)

      return () => clearTimeout(timeout)
    }, [value])

    const [isFocused, setIsFocused] = useState(false)

    return (
      <div
        className={`group ${containerClassName} flex items-center pl-3 pr-2 focus-within:bg-brand-gray-100 hover:bg-brand-gray-100 rounded-lg bg-brand-gray-50 gap-3 border border-solid h-full relative transition-all`}
      >
        <div className="flex items-center gap-1 w-full">
          {renderLeft}
          <input
            ref={ref}
            onFocus={() => setIsFocused(() => true)}
            onBlur={() => setIsFocused(() => false)}
            {...props}
            className={`focus:bg-brand-gray-100 group-hover:bg-brand-gray-100 outline-none border-none bg-brand-gray-50 transition-all ${props.className}`}
            value={value}
            onChange={e => {
              setValue(e.target.value)
            }}
          />
        </div>

        <div
          className={`absolute right-2 ${
            isFocused ? 'opacity-0' : 'opacity-100'
          } transition-all`}
        >
          {renderRight}
        </div>
      </div>
    )
  }
)

function statusIcon(status: SchemaType) {
  switch (status) {
    case 'status':
      return faList
    case 'date':
      return faCalendar
    case 'number':
      return faHashtag
    case 'string':
      return faFont
    default:
      return faFont
  }
}

function TableHeader({
  table,
  maxVisibleColumns,
}: {
  table: Table<any>
  maxVisibleColumns: number
}) {
  return (
    <div className="bg-brand-gray-50 border-b border-solid border-gray-300 py-1 h-[45px] relative">
      {table.getHeaderGroups().map(headerGroup => (
        <div key={headerGroup.id} className="flex justify-between px-3 h-full">
          <div className={`grid ${'grid-cols-' + maxVisibleColumns} w-full`}>
            {headerGroup.headers.map((header, index) => {
              const sortedDir = header.column.getIsSorted() as string
              return (
                <div
                  key={header.id}
                  {...{
                    className: header.column.getCanSort()
                      ? 'cursor-pointer select-none col-span-1 p-2 my-auto'
                      : 'col-span-1 p-2 my-auto',
                    onClick: header.column.getToggleSortingHandler(),
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div className="flex gap-2 items-center">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className={`text-[10px] -mb-[5px] ${
                            sortedDir === 'desc'
                              ? 'text-brand-gray-200'
                              : 'text-brand-gray-800'
                          }  `}
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`text-[10px] ${
                            sortedDir === 'asc'
                              ? 'text-brand-gray-200'
                              : 'text-brand-gray-800'
                          }`}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
          <div className="w-[30px]" />
        </div>
      ))}
    </div>
  )
}

function RowsLoading({
  table,
  maxVisibleColumns,
}: {
  table: Table<any>
  maxVisibleColumns: number
}) {
  return (
    <>
      {Array.from(Array(table.getState().pagination.pageSize)).map(
        (row, index) => (
          <div
            key={index}
            className="h-[55px] flex justify-between border-b border-solid border-brand-gray-200 px-3"
          >
            <div className={`grid ${'grid-cols-' + maxVisibleColumns} w-full`}>
              {Array.from(Array(maxVisibleColumns)).map((skeleton, index) => {
                return (
                  <div
                    className={`col-span-1 p-2 my-auto relative`}
                    key={index}
                  >
                    <Skeleton height={23} radius={15} width={'70%'} />
                  </div>
                )
              })}
            </div>
            <div className="w-[30px]" />
          </div>
        )
      )}
    </>
  )
}

function Rows<T>({
  table,
  onClick,
  maxVisibleColumns,
  schema,
  menuActions,
}: {
  table: Table<any>
  onClick: (value: T) => void
  maxVisibleColumns: number
  schema: SchemaItem<T>[]
  menuActions: MenuAction<T>[]
}) {
  return (
    <>
      {table.getRowModel().rows.map(row => (
        <div
          key={row.id}
          className="transition-all min-h-[55px] flex justify-between border-b border-solid border-brand-gray-200 px-3 cursor-pointer hover:shadow-[0_0px_22px_-8px_rgba(0,0,0,0.3)]"
          onClick={() => onClick(row.original)}
        >
          <div className={`grid ${'grid-cols-' + maxVisibleColumns} w-full`}>
            {row.getVisibleCells().map((cell, index) => {
              const schemaColumn = schema.find(
                item => item.accessorKey === cell.column.id
              )
              const newlyAddedSchema =
                index === 0 ? schema.find(item => item.newlyAdded) : null
              const newlyAdded =
                index === 0 &&
                newlyAddedSchema?.newlyAdded &&
                moment(cell.row.original[newlyAddedSchema.newlyAdded]).isAfter(
                  moment().subtract(12, 'hours')
                )
              return (
                <div
                  className={`col-span-1 p-2 my-auto relative ${
                    schemaColumn?.type === 'string' ? 'truncate' : ''
                  }`}
                  key={cell.id}
                >
                  {newlyAdded && (
                    <div className="w-[6px] h-[6px] bg-brand-accent rounded-full absolute -left-[5px] top-[44%]" />
                  )}
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              )
            })}
          </div>
          <MenuButton menuActions={menuActions} original={row.original} />
        </div>
      ))}
    </>
  )
}

function CardsLoading({ table }: { table: Table<any> }) {
  return (
    <>
      {Array.from(Array(table.getState().pagination.pageSize)).map(
        (row, index) => (
          <div key={index} className="rounded-lg shadow col-span-1 h-[242px]">
            <Skeleton height={'100%'} w={'100%'} radius={6} />
          </div>
        )
      )}
    </>
  )
}

function Cards<T>({
  table,
  onClick,
  schema,
  menuActions,
}: {
  table: Table<any>
  onClick: (value: T) => void
  schema: SchemaItem<T>[]
  menuActions: MenuAction<T>[]
}) {
  return (
    <>
      {table.getRowModel().rows.map((row, index) => (
        <div
          key={row.id}
          className="transition-all border border-solid border-brand-gray-200 bg-white rounded-lg shadow hover:shadow-lg col-span-1 p-2 h-[242px] cursor-pointer"
          onClick={() => onClick(row.original)}
        >
          <div className={`flex flex-col w-full`}>
            {row
              .getVisibleCells()
              .filter(
                item =>
                  item.column.id ===
                  schema.find(item2 => item2.cardTitle)?.accessorKey
              )
              .map(cell => {
                const newlyAddedSchema = schema.find(item => item.newlyAdded)

                const newlyAdded =
                  newlyAddedSchema?.newlyAdded &&
                  moment(
                    cell.row.original[newlyAddedSchema.newlyAdded]
                  ).isAfter(moment().subtract(12, 'hours'))

                return (
                  <div key={cell.id} className="grid grid-cols-2 mb-2">
                    <div
                      className={`col-span-1 truncate p-2 font-semi items-center relative my-auto ${
                        newlyAdded ? 'pl-8' : ''
                      }`}
                      key={cell.id}
                    >
                      {newlyAdded && (
                        <div className="w-[12px] h-[12px] bg-blue-500 rounded-full absolute left-[9px] top-[31%]"></div>
                      )}
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                    <div className="col-span-1 p-2 text-end justify-end flex items-center">
                      <MenuButton
                        menuActions={menuActions}
                        original={row.original}
                      />
                    </div>
                  </div>
                )
              })}
            {row
              .getVisibleCells()
              .filter(item =>
                schema
                  .filter(item2 => !item2.cardTitle)
                  .map(item2 => item2.accessorKey)
                  .includes(item.column.id)
              )
              .map(cell => {
                const schemaColumn = schema.find(
                  item => item.accessorKey === cell.column.id
                )
                if (!schemaColumn) return

                return (
                  <div key={cell.id} className="grid grid-cols-2">
                    <div className="col-span 1 flex p-2 items-center gap-2 text-brand-gray-600">
                      <FontAwesomeIcon icon={statusIcon(schemaColumn.type)} />
                      <div className="truncate">
                        {cell.column.columnDef.header?.toString()}
                      </div>
                    </div>
                    <div
                      className="col-span-1 truncate p-2 text-end place-self-end flex items-center w-full justify-end"
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      ))}
    </>
  )
}

function FiltersNoMatches({ table }: { table: Table<any> }) {
  return (
    <>
      <div className="text-xl mb-3 text-center">No data</div>
      <div className="text-sm text-brand-gray-500 text-center">
        We couldn't find any data matching the current filters.
      </div>
      <div className="text-sm text-brand-gray-500 text-center">
        Try different filters or clear them.
      </div>
      <button
        className="flex gap-2 items-center bg-brand-gray-50 border-brand-gray-200 border border-solid rounded-lg py-1.5 px-3 mt-6 hover:bg-brand-gray-100 transition-all"
        onClick={() => {
          table.resetColumnFilters()
          table.resetGlobalFilter()
        }}
      >
        <FontAwesomeIcon icon={faRefresh} />
        <div>Clear filters</div>
      </button>
    </>
  )
}

function FilterButtons<T>({
  schema,
  table,
  columnVisibility,
}: {
  schema: SchemaItem<T>[]
  table: Table<any>
  columnVisibility: VisibilityState
}) {
  return (
    <>
      {schema
        .filter(
          item => item.type !== 'string' && columnVisibility[item.accessorKey]
        )
        .map(item => {
          const schemaColumn = table
            .getAllColumns()
            .find(column => column.id === item.accessorKey)

          if (!schemaColumn) return

          switch (item.type) {
            case 'number':
              const filterValueNumbers = (schemaColumn.getFilterValue() as [
                number,
                number,
              ]) ?? [undefined, undefined]

              return (
                <Popover
                  key={item.accessorKey}
                  width={400}
                  trapFocus
                  position="bottom-start"
                  shadow="md"
                >
                  <Popover.Target>
                    <button className="flex gap-2 items-center bg-brand-gray-50 border border-solid border-brand-gray-300 h-full rounded-lg px-3 hover:bg-brand-gray-100 transition-all duration-300 text-brand-gray-800">
                      <div>
                        {schemaColumn.getIsFiltered()
                          ? `${
                              filterValueNumbers[0] && !filterValueNumbers[1]
                                ? 'Min: '
                                : ''
                            }${filterValueNumbers[0] ?? ''}${
                              filterValueNumbers[0] && filterValueNumbers[1]
                                ? ' - '
                                : ''
                            }${
                              filterValueNumbers[1] && !filterValueNumbers[0]
                                ? 'Max: '
                                : ''
                            }${filterValueNumbers[1] ?? ''}`
                          : item.header}
                      </div>
                      {!schemaColumn.getIsFiltered() ? (
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="text-[10px] text-brand-gray-800"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="text-[15px] text-brand-gray-800"
                          onClick={e => {
                            e.stopPropagation()
                            schemaColumn.setFilterValue(undefined)
                          }}
                        />
                      )}
                    </button>
                  </Popover.Target>
                  <Popover.Dropdown className="!rounded-lg">
                    <NumberFilterInputs
                      popover
                      filterValueNumbers={filterValueNumbers}
                      schemaColumn={schemaColumn}
                    />
                  </Popover.Dropdown>
                </Popover>
              )
            case 'date':
              return (
                <DatePickerInput
                  key={item.accessorKey}
                  valueFormat="DD MMM"
                  value={
                    ((schemaColumn.getFilterValue() as [string, string])?.map(
                      item => (item ? new Date(item) : null)
                    ) as [DateValue, DateValue]) ?? [undefined, undefined]
                  }
                  classNames={{
                    input:
                      '!bg-brand-gray-50 !border  !border-solid !border-brand-gray-300 !h-full !rounded-lg !text-sm hover:!bg-brand-gray-100 !transition-all !duration-300 !text-brand-gray-800 !font-medium',
                    root: '!text-black !h-full',
                    wrapper: '!h-full',
                  }}
                  popoverProps={{
                    classNames: {
                      dropdown: '!rounded-lg !max-w-auto !w-[300px]',
                    },
                  }}
                  placeholder={item.header}
                  rightSection={
                    !schemaColumn.getIsFiltered() ? (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="text-[10px] text-brand-gray-800"
                      />
                    ) : undefined
                  }
                  rightSectionPointerEvents={
                    !schemaColumn.getIsFiltered() ? 'none' : undefined
                  }
                  clearable
                  type="range"
                  onChange={v => {
                    // if (!v[1] && v[0]) return
                    if (!v[1] && !v[0]) {
                      schemaColumn.setFilterValue(undefined)
                      return
                    }
                    schemaColumn.setFilterValue(() => [v[0], v[1]])
                  }}
                />
              )
            case 'status':
              const sortedUniqueValues = Array.from(
                schemaColumn.getFacetedUniqueValues().keys()
              ).sort()
              if (sortedUniqueValues.length <= 1) return
              const filterValue =
                (schemaColumn.getFilterValue() as string[]) ?? []

              return (
                <Menu
                  key={item.accessorKey}
                  shadow="md"
                  width={280}
                  position="bottom-start"
                  closeOnItemClick={false}
                >
                  <Menu.Target>
                    <button className="flex gap-2 items-center bg-brand-gray-50 border border-solid border-brand-gray-300 h-full rounded-lg px-3 hover:bg-brand-gray-100 transition-all duration-300 text-brand-gray-800">
                      <div>{item.header}</div>
                      <div className="bg-brand-gray-200 text-brand-gray-800 rounded-xl px-2">
                        {sortedUniqueValues.length - filterValue.length}/
                        {sortedUniqueValues.length}
                      </div>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="text-[10px] text-brand-gray-800"
                      />
                    </button>
                  </Menu.Target>
                  <Menu.Dropdown classNames={{ dropdown: '!rounded-lg' }}>
                    {sortedUniqueValues.map(uniqueValue => {
                      return (
                        <Menu.Item
                          key={uniqueValue}
                          className="!py-2"
                          onClick={() => {
                            schemaColumn.setFilterValue(
                              (oldValue: string[]) => {
                                if ((oldValue ?? []).includes(uniqueValue))
                                  return oldValue.filter(v => v !== uniqueValue)
                                return Array.from(
                                  new Set([...(oldValue ?? []), uniqueValue])
                                )
                              }
                            )
                          }}
                          rightSection={
                            !filterValue.includes(uniqueValue) ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-brand-gray-800"
                              />
                            ) : undefined
                          }
                        >
                          {uniqueValue}
                        </Menu.Item>
                      )
                    })}
                  </Menu.Dropdown>
                </Menu>
              )
            default:
              break
          }
        })}
      {(table.getAllColumns().some(item => item.getIsFiltered()) ||
        table.getState().globalFilter) && (
        <button
          className="flex gap-2 items-center hover:bg-brand-gray-50 transition-all rounded-lg px-3"
          onClick={() => {
            table.resetColumnFilters()
            table.resetGlobalFilter()
          }}
        >
          <FontAwesomeIcon icon={faRefresh} />
          <div>Clear filters</div>
        </button>
      )}
    </>
  )
}

function FilterDrawerContent<T>({
  schema,
  table,
  columnVisibility,
  onClose,
}: {
  schema: SchemaItem<T>[]
  table: Table<any>
  columnVisibility: VisibilityState
  onClose: () => void
}) {
  const getContent = (
    schemaItem: SchemaItem<T>,
    schemaColumn: Column<any, unknown>
  ) => {
    switch (schemaItem.type) {
      case 'number':
        const filterValueNumbers = (schemaColumn.getFilterValue() as [
          number,
          number,
        ]) ?? [undefined, undefined]

        return (
          <NumberFilterInputs
            filterValueNumbers={filterValueNumbers}
            schemaColumn={schemaColumn}
          />
        )
      case 'date':
        return (
          <DatePickerInput
            key={schemaItem.accessorKey}
            valueFormat="DD MMM"
            value={
              (schemaColumn.getFilterValue() as [DateValue, DateValue]) ?? [
                undefined,
                undefined,
              ]
            }
            classNames={{
              input:
                '!bg-brand-gray-100 !border !border-solid !border-brand-gray-100 !h-[40px] !rounded-lg !text-sm !font-medium !text-brand-gray-800',
              root: '!text-black !h-full',
              wrapper: '!h-full',
            }}
            rightSection={
              <FontAwesomeIcon
                icon={faChevronDown}
                className="text-[10px] text-brand-gray-800"
              />
            }
            popoverProps={{
              classNames: { dropdown: '!rounded-lg' },
            }}
            placeholder={schemaItem.header}
            clearable
            type="range"
            onChange={v => {
              // if (!v[1] && v[0]) return
              if (!v[1] && !v[0]) {
                schemaColumn.setFilterValue(undefined)
                return
              }
              schemaColumn.setFilterValue(() => [v[0], v[1]])
            }}
          />
        )
      case 'status':
        const sortedUniqueValues = Array.from(
          schemaColumn.getFacetedUniqueValues().keys()
        ).sort()
        if (sortedUniqueValues.length <= 1) return
        const filterValue = (schemaColumn.getFilterValue() as string[]) ?? []

        return (
          <div className="flex flex-wrap gap-1.5 text-sm font-medium">
            <div
              className={`${
                filterValue.length === 0
                  ? 'bg-brand-gray-800 text-white'
                  : 'bg-brand-gray-100'
              } h-[40px] px-4 rounded-full cursor-pointer flex items-center justify-center`}
              onClick={e => {
                schemaColumn.setFilterValue((oldValue: string[]) => [])
              }}
            >
              All
            </div>
            {sortedUniqueValues.map(uniqueValue => {
              return (
                <div
                  key={uniqueValue}
                  className={`${
                    filterValue.includes(uniqueValue) ||
                    filterValue.length === 0
                      ? 'bg-brand-gray-100 text-brand-gray-800'
                      : 'bg-brand-gray-800 text-white'
                  }  h-[40px] px-4 rounded-full cursor-pointer flex items-center justify-center`}
                  onClick={e => {
                    schemaColumn.setFilterValue((oldValue: string[]) => {
                      if ((oldValue ?? []).length === 0)
                        return sortedUniqueValues.filter(v => v !== uniqueValue)
                      if (oldValue.includes(uniqueValue))
                        return oldValue.filter(v => v !== uniqueValue)
                      return Array.from(
                        new Set([...(oldValue ?? []), uniqueValue])
                      )
                    })
                  }}
                >
                  {uniqueValue}
                </div>
              )
            })}
          </div>
        )
      default:
        break
    }
  }

  const currentSortedHeader = table
    .getHeaderGroups()
    .find(item => item.headers.find(item2 => item2.column.getIsSorted))
    ?.headers.find(item => item.column.getIsSorted())

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="overflow-y-auto">
        {schema
          .filter(
            item => item.type !== 'string' && columnVisibility[item.accessorKey]
          )
          .map(item => {
            const schemaColumn = table
              .getAllColumns()
              .find(column => column.id === item.accessorKey)

            if (!schemaColumn) return

            return (
              <div
                key={item.accessorKey}
                className="border-b border-solid border-brand-gray-200 flex flex-col gap-5 p-3 pb-6 mb-4"
              >
                <p className="text-lg font-semi">{item.header}</p>
                <div>{getContent(item, schemaColumn)}</div>
              </div>
            )
          })}
        <div className="flex flex-col gap-5 p-3 pb-6 mb-4">
          <p className="text-lg font-semi">Sort by</p>
          <Menu
            shadow="md"
            width={300}
            position="bottom-start"
            closeOnItemClick={false}
          >
            <Menu.Target>
              <button className="bg-brand-gray-100 border border-solid border-brand-gray-100 h-[40px] rounded-lg text-sm font-medium text-brand-gray-800 flex justify-between items-center px-3">
                {currentSortedHeader?.column.getIsSorted()
                  ? (currentSortedHeader.column.columnDef.header?.toString() ??
                      '') +
                    ' - ' +
                    currentSortedHeader.column.getIsSorted() +
                    'ending'
                  : 'None'}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="text-[10px] text-brand-gray-800"
                />
              </button>
            </Menu.Target>
            <Menu.Dropdown classNames={{ dropdown: '!rounded-lg' }}>
              {table.getHeaderGroups()[0].headers.map(item => (
                <Menu.Item
                  className="!py-2"
                  onClick={() => {
                    item.column.toggleSorting()
                  }}
                >
                  <div className="flex justify-between items-center text-xs text-brand-gray-800 font-medium">
                    {item.column.columnDef.header?.toString()}
                    {item.column.getIsSorted() && (
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className={`text-[10px] -mb-[5px] ${
                            item.column.getIsSorted() === 'desc'
                              ? 'text-brand-gray-200'
                              : 'text-brand-gray-800'
                          }  `}
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`text-[10px] ${
                            item.column.getIsSorted() === 'asc'
                              ? 'text-brand-gray-200'
                              : 'text-brand-gray-800'
                          }`}
                        />
                      </div>
                    )}
                  </div>
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>

      <div className="h-[100px] bg-brand-gray-50 border-t border-solid border-brand-gray-200 flex items-center justify-between px-4">
        {table.getAllColumns().some(item => item.getIsFiltered()) ||
        table.getState().globalFilter ? (
          <button
            className="flex gap-2 items-center border-brand-gray-200 border border-solid transition-all rounded-lg px-3 h-[42px] font-medium text-brand-gray-800"
            onClick={() => {
              table.resetColumnFilters()
              table.resetGlobalFilter()
            }}
          >
            Clear filters
          </button>
        ) : (
          <div></div>
        )}
        <button
          className="flex gap-2 items-center bg-brand-accent text-white transition-all rounded-lg px-3 h-[42px] font-medium"
          onClick={onClose}
        >
          Show {table.getFilteredRowModel().rows.length} results
        </button>
      </div>
    </div>
  )
}

function NumberFilterInputs({
  filterValueNumbers,
  schemaColumn,
  popover,
}: {
  filterValueNumbers: [number, number]
  schemaColumn: Column<any, unknown>
  popover?: boolean
}) {
  return (
    <div className="flex gap-3 items-center w-full">
      <div className="w-full">
        <p className="text-xs text-brand-gray-500">Minimum</p>
        <div className="w-full h-[40px] mt-1">
          <DebouncedInput
            value={filterValueNumbers[0]}
            containerClassName={`${
              popover
                ? 'border-brand-gray-300'
                : '!bg-brand-gray-100 border-brand-gray-100'
            }`}
            className={`rounded-lg text-md font-medium text-brand-gray-800 w-full ${
              popover
                ? 'border border-solid border-brand-gray-300'
                : 'bg-brand-gray-100 text-base'
            }`}
            type="number"
            placeholder="0"
            onChange={v => {
              if (!v && !filterValueNumbers[1]) {
                schemaColumn.setFilterValue(undefined)
                return
              }
              schemaColumn.setFilterValue((old: [number, number]) => [
                v,
                old?.[1],
              ])
            }}
          />
        </div>
      </div>
      <div className="w-[14px] h-[1px] bg-brand-gray-300 mt-5" />
      <div className="w-full">
        <p className="text-xs text-brand-gray-500">Maximum</p>
        <div className="w-full h-[40px] mt-1">
          <DebouncedInput
            value={filterValueNumbers[1]}
            containerClassName={`${
              popover
                ? 'border-brand-gray-300'
                : '!bg-brand-gray-100 border-brand-gray-100'
            }`}
            className={`rounded-lg text-md font-medium text-brand-gray-800 w-full ${
              popover
                ? 'border border-solid border-brand-gray-300'
                : 'bg-brand-gray-100 text-base'
            }`}
            type="number"
            placeholder="0"
            onChange={v => {
              if (!v && !filterValueNumbers[0]) {
                schemaColumn.setFilterValue(undefined)
                return
              }
              schemaColumn.setFilterValue((old: [number, number]) => [
                old?.[0],
                v,
              ])
            }}
          />
        </div>
      </div>
    </div>
  )
}

function MenuButton<T>({
  original,
  menuActions,
}: {
  original: T
  menuActions: MenuAction<T>[]
}) {
  return (
    <Menu shadow="md" width={200} position="bottom-end">
      <Menu.Target>
        <button
          onClick={e => e.stopPropagation()}
          className="my-auto hover:bg-brand-gray-50 w-[30px] h-[30px] rounded-md"
        >
          <FontAwesomeIcon icon={faEllipsis} className="text-brand-gray-500" />
        </button>
      </Menu.Target>
      <Menu.Dropdown classNames={{ dropdown: '!rounded-lg' }}>
        {menuActions
          .filter(action => !action.hidden(original))
          .map(action => {
            return (
              <Menu.Item
                key={action.title}
                className="!py-2"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.stopPropagation()
                  action.onClick(original)
                }}
              >
                {action.title}
              </Menu.Item>
            )
          })}
      </Menu.Dropdown>
    </Menu>
  )
}

function SelectColumnMenu<T>({
  forDrawer,
  table,
  schema,
  tableType,
  maxVisibleColumns,
  enabledColumns,
}: {
  forDrawer?: boolean
  table: Table<any>
  schema: SchemaItem<T>[]
  tableType: TableType
  maxVisibleColumns: number
  enabledColumns: [string, boolean][]
}) {
  const statusOrder = {
    status: 1,
    date: 2,
    number: 3,
    string: 4,
  }

  return (
    <Menu
      shadow="md"
      width={280}
      position="bottom-start"
      closeOnItemClick={false}
    >
      <Menu.Target>
        <button
          className={`flex gap-2 items-center ${
            forDrawer
              ? 'bg-brand-gray-100 h-[40px] rounded-full'
              : 'bg-brand-gray-50 border-brand-gray-300 border-solid border h-full rounded-lg'
          } px-4 hover:bg-brand-gray-100 transition-all duration-300 text-brand-gray-800`}
        >
          {forDrawer && (
            <FontAwesomeIcon
              icon={faTableColumns}
              className="text-[15px] text-brand-gray-800"
            />
          )}
          <div className="text-brand-gray-800 font-medium text-sm">
            {forDrawer
              ? table.getAllColumns().filter(item => item.getIsVisible())
                  .length + ' columns selected'
              : 'Select columns'}
          </div>
          <FontAwesomeIcon
            icon={faChevronDown}
            className="text-[10px] text-brand-gray-800"
          />
        </button>
      </Menu.Target>
      <Menu.Dropdown classNames={{ dropdown: '!rounded-lg' }}>
        {table
          .getAllColumns()
          .sort((a, b) => {
            const schemaColumnA = schema.find(item => item.accessorKey === a.id)
            const schemaColumnB = schema.find(item => item.accessorKey === b.id)
            if (!schemaColumnA || !schemaColumnB) return -1
            return (
              statusOrder[schemaColumnA.type] - statusOrder[schemaColumnB.type]
            )
          })
          .map(column => {
            const schemaColumn = schema.find(
              item => item.accessorKey === column.id
            )
            if (!schemaColumn) return
            const disabled =
              schemaColumn?.permanent ||
              (!column.getIsVisible() &&
                enabledColumns.length >=
                  (tableType === 'table' ? maxVisibleColumns : 5))
            const selected = column.getIsVisible()
            return (
              <Tooltip
                key={column.id}
                position="bottom"
                multiline
                maw={320}
                classNames={{ tooltip: '!bg-brand-gray-800' }}
                disabled={!schemaColumn.permanent && !disabled}
                transitionProps={{ transition: 'pop' }}
                label={
                  schemaColumn.permanent
                    ? "This column is locked and can't be replaced."
                    : disabled
                      ? `You have reached a maximum of ${maxVisibleColumns} columns, deselect one to show another.`
                      : ''
                }
              >
                <Menu.Item
                  className="!py-2"
                  classNames={{
                    item: disabled
                      ? 'hover:!bg-transparent !text-brand-gray-300'
                      : '',
                  }}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    if (disabled) return
                    column.toggleVisibility(!column.getIsVisible())
                    column.setFilterValue(() => undefined)
                  }}
                  rightSection={
                    selected ? (
                      <FontAwesomeIcon
                        icon={schemaColumn.permanent ? faLock : faCheck}
                        className={
                          disabled
                            ? 'text-brand-gray-300'
                            : 'text-brand-gray-800'
                        }
                      />
                    ) : undefined
                  }
                  leftSection={
                    <FontAwesomeIcon
                      icon={statusIcon(schemaColumn.type)}
                      className={
                        disabled ? 'text-brand-gray-300' : 'text-brand-gray-400'
                      }
                    />
                  }
                >
                  {schemaColumn?.header}
                </Menu.Item>
              </Tooltip>
            )
          })}
      </Menu.Dropdown>
    </Menu>
  )
}

function TableTypeButtons({
  tableType,
  setTableType,
}: {
  tableType: string
  setTableType: Dispatch<SetStateAction<TableType>>
}) {
  return (
    <div className="h-full">
      <button
        className={`${
          tableType === 'table'
            ? 'bg-brand-gray-200 shadow-inner'
            : 'bg-brand-gray-50'
        } p-2 w-[44px] rounded-l-lg border-y border-l border-solid border-brand-gray-300 h-full transition-all`}
        onClick={() => setTableType('table')}
      >
        <FontAwesomeIcon
          icon={faList}
          className={`${
            tableType === 'table'
              ? 'text-brand-gray-950'
              : 'text-brand-gray-500'
          }`}
        />
      </button>
      <button
        className={`${
          tableType === 'cards'
            ? 'bg-brand-gray-200 shadow-inner'
            : 'bg-brand-gray-50'
        } p-2 w-[44px] rounded-r-lg border border-solid border-brand-gray-300 h-full transition-all`}
        onClick={() => setTableType('cards')}
      >
        <FontAwesomeIcon
          icon={faTableCellsLarge}
          className={`${
            tableType === 'cards'
              ? 'text-brand-gray-950'
              : 'text-brand-gray-500'
          }`}
        />
      </button>
    </div>
  )
}

function Pagination({ table }: { table: Table<any> }) {
  return (
    <div className="flex flex-col items-center justify-center gap-5">
      <div className="flex">
        <button
          className={`border-y border-l border-solid border-brand-gray-300 h-[42px] w-[42px] rounded-l-lg transition-all ${
            !table.getCanPreviousPage()
              ? 'text-brand-gray-400'
              : 'hover:bg-brand-gray-50'
          }`}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>

        <button
          className={`h-[42px] w-[42px] hover:bg-brand-gray-50 transition-all ${
            table.getState().pagination.pageIndex === 0
              ? 'border-brand-gray-800 border-2 font-semi'
              : 'border border-solid border-brand-gray-300'
          }`}
          onClick={() => table.setPageIndex(0)}
        >
          1
        </button>
        {table.getState().pagination.pageIndex > 2 &&
          table.getPageCount() > 6 && (
            <div className="border-y border-r border-solid border-brand-gray-300 h-[42px] w-[42px] text-brand-gray-300 flex justify-center items-center">
              <FontAwesomeIcon icon={faEllipsis} />
            </div>
          )}

        {Array.from(Array(table.getPageCount()).keys())
          .slice(1, -1)
          .filter((item, index) => {
            const pIndex = table.getState().pagination.pageIndex
            const pCount = table.getPageCount()
            const difference = item - pIndex
            if (pIndex < 3 && item < 5) return true
            if (pIndex > pCount - 4 && item > pCount - 6) return true
            if (difference < 2 && difference > -2) return true
            return false
          })
          .map(item => (
            <button
              key={item}
              className={`h-[42px] w-[42px] hover:bg-brand-gray-50 transition-all ${
                table.getState().pagination.pageIndex === item
                  ? 'border-brand-gray-800 border-2 font-semi'
                  : table.getState().pagination.pageIndex === item + 1
                    ? 'border-y border-solid border-brand-gray-300'
                    : 'border-y border-r border-solid border-brand-gray-300'
              }`}
              onClick={() => table.setPageIndex(item)}
            >
              {item + 1}
            </button>
          ))}
        {table.getState().pagination.pageIndex < table.getPageCount() - 3 &&
          table.getPageCount() > 6 && (
            <div className="border-y border-r border-solid border-brand-gray-300 h-[42px] w-[42px] text-brand-gray-300 flex justify-center items-center">
              <FontAwesomeIcon icon={faEllipsis} />
            </div>
          )}
        {table.getPageCount() > 1 && (
          <button
            className={`h-[42px] w-[42px] hover:bg-brand-gray-50 transition-all ${
              table.getState().pagination.pageIndex === table.getPageCount() - 1
                ? 'border-brand-gray-800 border-2 font-semi'
                : 'border-y border-r border-solid border-brand-gray-300'
            }`}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          >
            {table.getPageCount()}
          </button>
        )}

        <button
          className={`border-y border-r border-solid border-brand-gray-300 h-[42px] w-[42px] rounded-r-lg transition-all ${
            !table.getCanNextPage()
              ? 'text-brand-gray-400'
              : 'hover:bg-brand-gray-50'
          }`}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
      <div className="text-brand-gray-400 text-sm">
        {table.getState().pagination.pageSize *
          table.getState().pagination.pageIndex +
          (table.getFilteredRowModel().rows.length === 0 ? 0 : 1)}
        -
        {table.getState().pagination.pageSize *
          (table.getState().pagination.pageIndex + 1) >
        table.getFilteredRowModel().rows.length
          ? table.getFilteredRowModel().rows.length
          : table.getState().pagination.pageSize *
            (table.getState().pagination.pageIndex + 1)}{' '}
        out of {table.getFilteredRowModel().rows.length} results
      </div>
    </div>
  )
}

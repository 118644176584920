import { Spinner, useDisclosure } from '@chakra-ui/react'
import type React from 'react'
import { useState } from 'react'
import type { SwapDto } from '../../../../../types/coreApi-types'
import StatusModal from '../containers/StatusModal'
import SwapsPlaceholder from './SwapsPlaceholder'
import TableRow from './TableRow'

interface TableProps {
  swaps?: SwapDto[]
  isLoading: boolean
}

const Table: React.FC<TableProps> = ({ swaps, isLoading }) => {
  const statusModal = useDisclosure()
  const [activeSwap, setActiveSwap] = useState<SwapDto | undefined>(undefined)

  const renderSwaps = () => {
    if (swaps?.length === 0) {
      return (
        <div className="h-full w-full flex items-center justify-center">
          <SwapsPlaceholder />
        </div>
      )
    } else {
      return (
        <>
          <div className="hidden lg:grid grid-cols-3 xl:grid-cols-6 p-6 pt-4 pb-3 sticky top-0 bg-brand-light-card dark:bg-brand-dark-card border-b-[1px] border-solid border-b-brand-light-card-border dark:border-b-brand-dark-card-border">
            <div className="table-head">Created</div>
            <div className="table-head">Status</div>
            <div className="table-head">Deposit</div>
            <div className="table-head">Receive</div>
            <div className="table-head">Address / Bank Details</div>
            <div className="table-head">Network / Bank Name</div>
          </div>
          <div>
            {swaps &&
              [...swaps].reverse().map((swap, idx) => {
                return (
                  <TableRow
                    swap={swap}
                    key={idx}
                    onClick={() => {
                      setActiveSwap(swap)
                      statusModal.onOpen()
                    }}
                  />
                )
              })}
          </div>
        </>
      )
    }
  }
  return (
    <div
      className={`bg-brand-light-card dark:bg-brand-dark-card rounded-lg border-[1px] lg:max-h-[550px] border-solid border-brand-light-card-border dark:border-brand-dark-card-border font-medium w-full transition-all flex flex-col relative overflow-y-auto  ${
        !swaps || isLoading ? 'justify-center items-center' : ''
      }`}
    >
      {activeSwap && (
        <StatusModal
          isOpen={statusModal.isOpen}
          onClose={() => {
            statusModal.onClose()
          }}
          swap={activeSwap}
        />
      )}
      {swaps && !isLoading ? (
        renderSwaps()
      ) : (
        <div>
          <Spinner size={'xl'} />
        </div>
      )}
    </div>
  )
}

export default Table

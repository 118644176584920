import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import type React from 'react'

interface CoptyTextWrapperProps {
  children: React.ReactNode
  text: string
}

const CoptyTextWrapper: React.FC<CoptyTextWrapperProps> = ({
  children,
  text,
}) => {
  const isDarkMode =
    document.body.getAttribute('theme') === 'dark' &&
    localStorage.getItem('color-theme') === 'dark'

  return (
    <div onClick={() => navigator.clipboard.writeText(text)}>
      <Popover>
        <PopoverTrigger>{children}</PopoverTrigger>

        <PopoverContent
          background={isDarkMode ? '#1A2128' : '#FFF'}
          p="8px 12px"
          w="min"
          shadow="none !important"
          border="0px solid #fff"
        >
          <PopoverArrow
            backgroundColor={
              isDarkMode ? '#1A2128 !important' : '#FFF !important'
            }
            border="0px solid #fff !important"
            shadow="none !important"
          />
          <div
            className={`font-semi text-xs ${
              isDarkMode ? 'text-white' : 'text-brand-light-text-default'
            }`}
          >
            Copied!
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default CoptyTextWrapper

import { Spinner, useDisclosure, useToast } from '@chakra-ui/react'
import {
  faArrowRight,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { captureException } from '@sentry/react'
import { useVirtualizer } from '@tanstack/react-virtual'
import moment from 'moment'
import type React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Download04Icon from '../../../../../../assets/download-04-stroke-rounded'
import Invoice01Icon from '../../../../../../assets/invoice-01-stroke-rounded'
import Loading03Icon from '../../../../../../assets/loading-03-stroke-rounded'
import UserIcon from '../../../../../../assets/user-stroke-rounded'
import DefaultModal from '../../../../../../components/DefaultModal'
import Toast from '../../../../../../components/Toast'
import Tooltip from '../../../../../../components/Tooltip'
import TransferStatusBadge from '../../../../../../components/TransferStatusBadge'
import { useSetSwapStatusMutation } from '../../../../../../redux/services/coreApi'
import type { RootState } from '../../../../../../redux/store'
import type {
  SwapDto,
  TransferStatus,
} from '../../../../../../types/coreApi-types'
import downloadSwapInvoice from '../../../../../../utils/downloadInvoice'
import { formatUtcDate } from '../../../../../../utils/formatters'
import TransactionSummaryModal from './TransactionSummaryModal'

interface TransfersProps {
  swapsData?: SwapDto[]
  ignoreUserLink?: boolean
}

type SortingKeyType = 'date' | 'status' | 'bankDepositUniqueId' | 'name' | null

const Transfers: React.FC<TransfersProps> = ({ swapsData, ignoreUserLink }) => {
  const toast = useToast()
  const history = useHistory()
  const session = useSelector((state: RootState) => state.session)

  const [setSwapStatus, { isLoading: setSwapsStatusIsLoading }] =
    useSetSwapStatusMutation()

  const [withdrawTransactionHash, setWithdrawTransactionHash] =
    useState<string>('')

  const [adminNote, setAdminNote] = useState<string>('')

  const parentRef = useRef(null)

  const headers: {
    title: string
    key: SortingKeyType
  }[] = [
    { title: 'Date', key: 'date' },
    { title: 'Status', key: 'status' },
    { title: 'Unique ID', key: 'bankDepositUniqueId' },
    { title: 'Name', key: 'name' },
  ]

  const [activeSwap, setActiveSwap] = useState<SwapDto | null>(null)
  const [tempTransferStatus, setTempTransferStatus] =
    useState<TransferStatus | null>(null)
  const summaryModal = useDisclosure()
  const confirmModal = useDisclosure()

  const [query, setQuery] = useState('')

  const [transactionsState, setTransactionsState] = useState<SwapDto[]>([])

  const [isDownloadingInvoice, setIsDownloadingInvoice] = useState<{
    [swapId: string]: boolean
  }>({})

  const [sortConfig, setSortConfig] = useState<{
    key: SortingKeyType
    direction: string | null
  }>({ key: null, direction: null })

  const rowVirtualizer = useVirtualizer({
    count: transactionsState.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 50,
    overscan: 10,
  })

  useEffect(() => {
    if (swapsData) {
      sortProducts(sortConfig?.key, swapsData)
    }
    if (activeSwap) {
      const foundSwap = swapsData?.find(
        (el: SwapDto) => el.id === activeSwap.id
      )
      setActiveSwap(foundSwap || null)
    }
  }, [swapsData])

  useEffect(() => {
    if (!swapsData) return
    setTransactionsState([
      ...(swapsData || []).filter(
        item =>
          (item.createdBy.name + item.createdBy.name)
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          item.deposit?.bankDepositUniqueId.includes(query)
      ),
    ])
  }, [query])

  const sortProducts = (sortKey: SortingKeyType, data?: SwapDto[]) => {
    if (sortKey !== null) {
      const listToSort = data ?? [...transactionsState]
      const sortedList = listToSort.slice().sort((a, b) => {
        if (sortKey === 'date') {
          if (moment(a.createdAt) < moment(b.createdAt)) {
            return sortConfig.direction === 'ascending' ? -1 : 1
          }
          if (moment(a.createdAt) > moment(b.createdAt)) {
            return sortConfig.direction === 'ascending' ? 1 : -1
          }
        }
        if (sortKey === 'name') {
          const aString = a.createdBy.name.toLowerCase()
          const bString = b.createdBy.name.toLowerCase()
          if (sortConfig.direction === 'ascending') {
            return aString.localeCompare(bString)
          }
          if (sortConfig.direction === 'descending') {
            return bString.localeCompare(aString)
          }
        }
        if (sortKey === 'status') {
          const aString = a.withdraw?.transferStatus.toString() ?? ''
          const bString = b.withdraw?.transferStatus.toString() ?? ''
          if (sortConfig.direction === 'ascending') {
            return aString.localeCompare(bString)
          }
          if (sortConfig.direction === 'descending') {
            return bString.localeCompare(aString)
          }
        }
        if (sortKey === 'bankDepositUniqueId') {
          const aString = a.deposit?.bankDepositUniqueId ?? ''
          const bString = b.deposit?.bankDepositUniqueId ?? ''
          if (sortConfig.direction === 'ascending') {
            return aString.localeCompare(bString)
          }
          if (sortConfig.direction === 'descending') {
            return bString.localeCompare(aString)
          }
        }
        return 0
      })

      setTransactionsState([...sortedList])
      return
    }
    const listToSort = data ?? [...transactionsState]
    const sortedList = listToSort.slice().sort((a, b) => {
      if (moment(a.createdAt) < moment(b.createdAt)) {
        return sortConfig.direction === 'ascending' ? -1 : 1
      }
      if (moment(a.createdAt) > moment(b.createdAt)) {
        return sortConfig.direction === 'ascending' ? 1 : -1
      }
      return 0
    })
    setTransactionsState([...sortedList])
  }

  const handleSortClick = (key: SortingKeyType) => {
    let direction: string | null = 'ascending'
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        direction = 'descending'
      } else if (sortConfig.direction === 'descending') {
        direction = null
        key = null
      }
    }
    setSortConfig({ key, direction })
    sortProducts(key)
  }

  return (
    <div>
      <input
        type="text"
        className="bg-white border-0 rounded-md pl-3 text-[13px] font-medium outline-0 mb-2 w-[240px]"
        placeholder="Filter by ID or Name"
        value={query}
        onChange={e => setQuery(e.target.value)}
      />
      <div className="bg-white relative h-[600px] overflow-hidden rounded-lg border-solid border-[1px] border-black/10">
        <div ref={parentRef} className="absolute inset-0 overflow-y-auto ">
          <div
            className={`sticky z-10 bg-white grid grid-cols-9 font-medium text-xs top-0 left-0 w-full border-b-[1px] border-brand-light-card-border border-solid`}
          >
            {headers.map(item => {
              return (
                <div
                  key={item.key}
                  className="flex items-center p-3 col-span-2 cursor-pointer select-none font-semi"
                  onClick={() => {
                    handleSortClick(item.key)
                  }}
                >
                  {item.title}
                  <div className="flex text-[14px] text-brand-text-description ml-2">
                    {sortConfig.key === item.key &&
                      (sortConfig.direction === 'ascending' ? (
                        <FontAwesomeIcon icon={faCaretUp} />
                      ) : sortConfig.direction === 'descending' ? (
                        <FontAwesomeIcon icon={faCaretDown} />
                      ) : null)}
                  </div>
                </div>
              )
            })}

            <div className="flex flex-row-reverse items-center p-3 "></div>
          </div>
          {transactionsState.length > 0 && (
            <div
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                position: 'relative',
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualItem: any) => (
                <div
                  key={transactionsState[virtualItem.index].id}
                  style={{
                    height: `${virtualItem.size}px`,
                    transform: `translateY(${virtualItem.start}px)`,
                    backgroundColor:
                      virtualItem.index % 2 === 1 ? '#F5F7F9' : '#fff',
                  }}
                  className="absolute grid grid-cols-9 font-medium text-[0.8rem] top-0 left-0 w-full border-b-[1px] border-brand-light-card-border border-solid"
                >
                  <div className="flex items-center px-3 col-span-2">
                    {formatUtcDate(
                      transactionsState[virtualItem.index].createdAt
                    )}
                  </div>
                  <div className="flex items-center px-3 col-span-2">
                    {transactionsState[virtualItem.index].withdraw
                      ?.transferStatus && (
                      <TransferStatusBadge
                        transferStatus={
                          transactionsState[virtualItem.index].withdraw
                            ?.transferStatus!
                        }
                      />
                    )}
                  </div>
                  <div className="flex items-center px-3 col-span-2">
                    {
                      transactionsState[virtualItem.index].deposit
                        ?.bankDepositUniqueId
                    }
                  </div>
                  <div className="flex items-center px-3 col-span-2">
                    {transactionsState[virtualItem.index].createdBy.name}
                  </div>

                  <div className="flex flex-row-reverse items-center px-3 gap-1">
                    {!ignoreUserLink && (
                      <Tooltip label="User details">
                        <button
                          onClick={() =>
                            history.push(
                              `/app/admin/clients/${
                                transactionsState[virtualItem.index].createdBy
                                  .id
                              }`
                            )
                          }
                          className="text-description px-2.5 flex items-center  gap-2 py-2 border-solid whitespace-nowrap text-xs border-brand-light-card-border border-[1px] rounded-[6px] hover:border-black transition-all hover:text-black"
                        >
                          <UserIcon className="w-4 h-4" />
                        </button>
                      </Tooltip>
                    )}
                    <Tooltip label="Order details">
                      <button
                        onClick={() => {
                          setActiveSwap(transactionsState[virtualItem.index])
                          setWithdrawTransactionHash(
                            transactionsState[virtualItem.index].withdraw
                              ?.transactionHash || ''
                          )
                          setAdminNote(
                            transactionsState[virtualItem.index].adminNote || ''
                          )

                          summaryModal.onOpen()
                        }}
                        className="text-description px-2.5 py-2 border-solid text-xs whitespace-nowrap border-brand-light-card-border border-[1px] rounded-[6px] hover:border-black transition-all hover:text-black"
                      >
                        <Invoice01Icon className="w-4 h-4 text-inherit" />
                      </button>
                    </Tooltip>

                    {transactionsState[virtualItem.index].withdraw
                      ?.transferStatus === 'Completed' && (
                      <Tooltip label="Download Receipt">
                        <button
                          onClick={async () => {
                            const swapId =
                              transactionsState[virtualItem.index].id
                            setIsDownloadingInvoice(prev => ({
                              ...prev,
                              [swapId]: true,
                            }))
                            try {
                              await downloadSwapInvoice(
                                transactionsState[virtualItem.index]?.id,
                                session.token
                              )
                            } catch (e) {
                              captureException(e)
                            } finally {
                              setIsDownloadingInvoice(prev => ({
                                ...prev,
                                [swapId]: false,
                              }))
                            }
                          }}
                          className="text-description flex gap-1 px-2.5 py-2 border-solid text-xs whitespace-nowrap border-brand-light-card-border border-[1px] rounded-[6px] hover:border-black transition-all hover:text-black"
                        >
                          {isDownloadingInvoice[
                            transactionsState[virtualItem.index].id
                          ] ? (
                            <Loading03Icon className="w-4 h-4 text-inherit animate-spin" />
                          ) : (
                            <Download04Icon className="w-4 h-4 text-inherit" />
                          )}
                        </button>
                      </Tooltip>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <TransactionSummaryModal
        isOpen={summaryModal.isOpen}
        onClose={summaryModal.onClose}
        activeSwap={activeSwap}
        withdrawTransactionHash={withdrawTransactionHash}
        setWithdrawTransactionHash={setWithdrawTransactionHash}
        setSwapStatus={updateSwapStatusCommand =>
          setSwapStatus(updateSwapStatusCommand).unwrap()
        }
        setSwapsStatusIsLoading={setSwapsStatusIsLoading}
        adminNote={adminNote}
        setAdminNote={setAdminNote}
        setTempTransferStatus={setTempTransferStatus}
        openConfirmModal={confirmModal.onOpen}
      />

      <DefaultModal
        isLoading={false}
        isOpen={confirmModal.isOpen}
        onClose={confirmModal.onClose}
        title="Are you sure?"
        maxW="500px"
      >
        <div className="flex flex-col items-center p-10 text-description-large">
          <p className="text-center">
            Are you sure you want to perform the following action:
          </p>

          <div className="flex justify-center items-center my-4 gap-2">
            {activeSwap?.withdraw?.transferStatus && (
              <TransferStatusBadge
                transferStatus={activeSwap?.withdraw?.transferStatus}
              />
            )}

            <FontAwesomeIcon icon={faArrowRight} className="text-black" />

            {tempTransferStatus && (
              <TransferStatusBadge transferStatus={tempTransferStatus} />
            )}
          </div>

          <p className="">With the following ID:</p>
          <div className="mt-3 py-1 px-2 bg-black/5 rounded">
            {activeSwap?.deposit?.bankDepositUniqueId}
          </div>

          <div className="flex gap-2 w-full mt-10">
            <button className="button-secondary" onClick={confirmModal.onClose}>
              Cancel
            </button>
            <button
              className="button"
              onClick={async () => {
                if (!activeSwap || !tempTransferStatus || !activeSwap.withdraw)
                  return

                await setSwapStatus({
                  swapId: activeSwap.id,
                  status: tempTransferStatus,
                  adminNote: adminNote,
                  transactionHash: withdrawTransactionHash,
                }).unwrap()
                confirmModal.onClose()

                toast({
                  position: 'top',
                  render: () => {
                    return (
                      <Toast type="success">
                        The status was successfully changed.
                      </Toast>
                    )
                  },
                })
              }}
            >
              {setSwapsStatusIsLoading ? <Spinner size="xs" /> : 'Confirm'}{' '}
            </button>
          </div>
        </div>
      </DefaultModal>
    </div>
  )
}

export default Transfers

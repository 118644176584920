import { useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import type React from 'react'
import { useEffect, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import AnimateHeight from '../../../../../components/AnimateHeight'
import DefaultModal from '../../../../../components/DefaultModal'
import FormCheckbox from '../../../../../components/FormCheckbox'
import FormInput from '../../../../../components/FormInput'
import FormSelect from '../../../../../components/FormSelect'
import InfoBox from '../../../../../components/InfoBox'
import MessageBox from '../../../../../components/MessageBox'
import SegmentTab from '../../../../../components/SegmentTab'
import Toast from '../../../../../components/Toast'
import {
  useCreateKycCaseMutation,
  useGetCountriesQuery,
  useInviteMemberMutation,
  useLinkUltimateBeneficialOwnersMutation,
} from '../../../../../redux/services/coreApi'
import { companyShareSchema } from '../../../../../utils/companyShareSchema'
import { fullNameRegex } from '../../../../Login/utils/fullNameValidator'

interface AddMemberModalProps {
  isOpen: boolean
  onClose: () => void
}

type AddMemberFormData = {
  name: string
  address: string
  phone: string
  email: string
  country: string

  hasPositionInCompany?: boolean
  companyTitle?: string

  isUltimateBeneficialOwner?: boolean
  sharePercentageOfCompany?: string
}

const Divider: React.FC = () => {
  return <div className="-mx-4 h-[1px] bg-black/5"></div>
}

const AddMemberModal: React.FC<AddMemberModalProps> = ({ isOpen, onClose }) => {
  const toast = useToast()
  const [inviteMember, { isLoading }] = useInviteMemberMutation()
  const [error, setError] = useState<string | null>(null)
  const [tab, setTab] = useState<'member' | 'ubo'>('member')
  const countries = useGetCountriesQuery()

  const schema: yup.SchemaOf<AddMemberFormData> = yup.object({
    name: yup
      .string()
      .required('This field is required.')
      .matches(fullNameRegex, 'Please enter at least your first and last name'),
    address: yup.string().required('This field is required.'),
    phone: yup.string().required('This field is required.'),
    country: yup.string().required('This field is required.'),
    email: yup
      .string()
      .email('Please enter a valid email.')
      .required('This field is required.'),
    hasPositionInCompany: yup.boolean(),
    companyTitle: yup.string().when('hasPositionInCompany', {
      is: true,
      then: yup.string().required('This field is required.'),
    }),
    isUltimateBeneficialOwner: yup.boolean(),
    sharePercentageOfCompany: yup.string().when(['isUltimateBeneficialOwner'], {
      is: true,
      then: companyShareSchema,
    }),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useForm<AddMemberFormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    setTab('member')
  }, [isOpen])

  useEffect(() => {
    if (tab === 'ubo') {
      setValue('isUltimateBeneficialOwner', true)
    } else {
      setValue('isUltimateBeneficialOwner', false)
    }
  }, [tab])

  const [hasPositionInCompany, isUltimateBeneficialOwner] = watch([
    'hasPositionInCompany',
    'isUltimateBeneficialOwner',
  ])

  const resetModal = () => {
    setValue('email', '')
    setValue('name', '')
    setValue('address', '')
    setValue('phone', '')
    setValue('country', '')
    setValue('hasPositionInCompany', false)
    setValue('companyTitle', '')
    setValue('isUltimateBeneficialOwner', false)
    setValue('sharePercentageOfCompany', '')
    clearErrors()
    setError(null)
    onClose()
  }

  const [linkUltimateBeneficialOwners, { isLoading: isLinkingUBO }] =
    useLinkUltimateBeneficialOwnersMutation()

  const [createKycCase, { isLoading: isCreatingKycCase }] =
    useCreateKycCaseMutation()

  const onSubmit: SubmitHandler<AddMemberFormData> = async ({
    name,
    address,
    phone,
    email,
    country,
    companyTitle,
    sharePercentageOfCompany,
    isUltimateBeneficialOwner,
  }) => {
    setError(null)
    const isoCode = countries.data?.find(el => el.name === country)?.isoCode
    if (!isoCode) return

    const companyShare =
      isUltimateBeneficialOwner && !!sharePercentageOfCompany
        ? Number.parseFloat(sharePercentageOfCompany)
        : undefined

    if (tab === 'member') {
      try {
        const res = await inviteMember({
          name,
          address,
          phone,
          email,
          isoCode,
          isUltimateBeneficialOwner,
          companyTitle: companyTitle ? companyTitle : null,
          sharePercentageOfCompany: companyShare,
        }).unwrap()

        try {
          await createKycCase({ memberId: res.userId }).unwrap()

          toast({
            position: 'bottom-right',
            render: () => (
              <Toast type="success">{`${name} has been successfully added as a Member of your organization, and have been sent instructions regarding KYC.`}</Toast>
            ),
          })
        } catch (e) {
          toast({
            position: 'bottom-right',
            render: () => (
              <Toast type="error">{`There was an error trying to create a KYC Case for ${name}.`}</Toast>
            ),
          })
        }
      } catch (err) {
        let errMsg = (err as any)?.data?.errors
        if (!errMsg)
          errMsg = `There was an error trying to add ${name} as a Member of your Organization.`

        toast({
          position: 'bottom-right',
          render: () => <Toast type="error">{errMsg}</Toast>,
        })
        //@ts-ignore
        setError('Something went wrong. Please contact support.')
        return
      }
    }

    if (tab === 'ubo' && !!sharePercentageOfCompany) {
      try {
        const result = await linkUltimateBeneficialOwners({
          links: [
            {
              name,
              address,
              phone,
              email,
              isoCode,
              sharePercentageOfCompany: companyShare!,
              companyTitle: companyTitle ? companyTitle : null,
            },
          ],
        }).unwrap()

        const uboLinkedMember = result.find(r => r.email === email)

        if (uboLinkedMember) {
          try {
            await createKycCase({ memberId: uboLinkedMember.id }).unwrap()

            toast({
              position: 'bottom-right',
              render: () => (
                <Toast type="success">{`${name} has been successfully added as an UBO of your Organization.`}</Toast>
              ),
            })
          } catch (e) {
            toast({
              position: 'bottom-right',
              render: () => (
                <Toast type="error">{`There was an error trying to create a KYC Case for ${name}.`}</Toast>
              ),
            })
          }
        }
      } catch (err) {
        toast({
          position: 'bottom-right',
          render: () => (
            <Toast type="error">{`There was an error trying to add ${name} as an UBO of your Organization.`}</Toast>
          ),
        })
        //@ts-ignore
        setError(err.data.errors)
        return
      }
    }

    resetModal()
  }

  const memberTitle = 'Invite account user by email'
  const uboTitle = 'Add Ultimate Beneficial Owner'

  return (
    <DefaultModal
      isLoading={isLoading || isLinkingUBO || isCreatingKycCase}
      isOpen={isOpen}
      onClose={() => {
        resetModal()
      }}
      title={tab === 'member' ? memberTitle : uboTitle}
      isLoadingText={tab === 'member' ? 'Inviting account user' : ''}
      whiteBG
    >
      <SegmentTab
        tabs={[
          {
            title: memberTitle,
            isActive: tab === 'member',
            onClick: () => {
              setTab('member')
              //setValue('isUltimateBeneficialOwner', false)
            },
            tooltip: 'This will be an account user without admin permissions.',
          },
          {
            title: uboTitle,
            isActive: tab === 'ubo',
            onClick: () => {
              setValue('isUltimateBeneficialOwner', false)
              setTab('ubo')
              //setValue('isUltimateBeneficialOwner', true)
            },
            tooltip:
              'This will be a ubo that doesnt have access to the system.',
          },
        ]}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <div className="p-5 pb-0 space-y-3">
          <FormInput
            label="Full name"
            inputMode="text"
            registeredForm={register('name')}
            error={errors?.name}
          />

          <FormInput
            label="Email"
            inputMode="email"
            registeredForm={register('email')}
            error={errors?.email}
          />

          <FormInput
            label="Address"
            inputMode="text"
            registeredForm={register('address')}
            error={errors?.address}
          />

          <FormInput
            label="Phone nr."
            inputMode="text"
            registeredForm={register('phone')}
            error={errors?.phone}
          />

          <FormSelect
            label="Country"
            options={countries.data?.map(item => item.name) || []}
            registeredForm={register('country')}
            placeholder="Select a country"
          />
          {/* <AnimateHeight isVisible={tab === 'ubo'}>
            <FormInput
              inputMode="numeric"
              label="Company shares"
              registeredForm={register('sharePercentageOfCompany')}
              error={errors?.sharePercentageOfCompany}
            />
          </AnimateHeight> */}
        </div>

        <div className="p-5 pb-0">
          <Divider />
        </div>

        <div>
          <div className="p-5 pb-3">
            <FormCheckbox
              id="has-position-in-company-check"
              renderLabel="Does the person hold a position in the company (i.e. employment)?"
              registeredForm={register('hasPositionInCompany')}
              error={errors.hasPositionInCompany}
            />
          </div>

          <AnimateHeight isVisible={!!hasPositionInCompany}>
            <div className="p-5 pt-1">
              <FormInput
                label="What is the title of the position?"
                inputMode="text"
                registeredForm={register('companyTitle')}
                error={errors.companyTitle}
              />
            </div>
          </AnimateHeight>
        </div>

        <div className="p-5 pt-1 pb-3">
          <FormCheckbox
            id="is-ubo-check"
            renderLabel="Is the person an ultimate owner/shareholder of the company?"
            registeredForm={register('isUltimateBeneficialOwner')}
            error={errors.isUltimateBeneficialOwner}
            disabled={tab === 'ubo'}
          />
        </div>

        <AnimateHeight isVisible={!!isUltimateBeneficialOwner}>
          <div className="p-5 pt-1">
            <FormInput
              label="What is your share of the company?"
              inputMode="numeric"
              registeredForm={register('sharePercentageOfCompany')}
              error={errors.sharePercentageOfCompany}
            />
          </div>
        </AnimateHeight>

        <div className="p-5 ">
          <InfoBox colorScheme="orange" column paddingLarge>
            <p className="font-semi">Important note </p>
            <ul className="list-disc ml-4">
              <li>
                Be sure you have the information correctly. It can't be undone.
              </li>
              <li>
                Your account will be frozen until the person has completed their
                KYC <br /> process.
              </li>
            </ul>
          </InfoBox>
        </div>

        <div className="p-5 pt-0">
          <button type="submit" className="button mt-10">
            {tab === 'member' ? 'Send invite' : 'Add UBO'}
          </button>
        </div>
      </form>

      <AnimateHeight isVisible={Boolean(error)}>
        <div className="p-5 pt-0">
          <MessageBox type="warning" title={error ?? ''} />
        </div>
      </AnimateHeight>
    </DefaultModal>
  )
}

export default AddMemberModal

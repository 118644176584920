import {
  faEye,
  faEyeSlash,
  faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import { type HTMLProps, useState } from 'react'
import type { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import Tooltip from './Tooltip'

interface FormInputProps {
  label?: string
  placeholder?: string
  error?: FieldError
  registeredForm: UseFormRegisterReturn
  inputMode: 'tel' | 'email' | 'text' | 'numeric'
  isSecure?: boolean
  disabled?: boolean
  onPressForgotPassword?: () => void
  showPassword?: boolean
  textarea?: boolean
  largeTitle?: boolean
  description?: string
  tooltip?: string
  className?: HTMLProps<HTMLElement>['className']
  cardStyling?: boolean
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  placeholder,
  error,
  registeredForm,
  inputMode,
  isSecure,
  disabled,
  onPressForgotPassword,
  showPassword,
  textarea,
  largeTitle,
  description,
  tooltip,
  className,
  cardStyling,
}) => {
  const [isShowingPassword, setIsShowingPassword] = useState(false)

  return (
    <div className={`space-y-1 ${className}`}>
      <div className="flex items-center justify-between">
        {label && (
          <label
            htmlFor={label}
            className={`flex items-center ${
              largeTitle
                ? 'font-semi text-base mb-3  text-brand-accent dark:text-white'
                : 'text-description'
            }`}
          >
            <div>{label}</div>

            {tooltip && (
              <Tooltip label={tooltip}>
                <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
              </Tooltip>
            )}
          </label>
        )}
        {description && (
          <label htmlFor={label} className="text-description">
            {description}
          </label>
        )}

        {onPressForgotPassword && (
          <div className="flex items-center font-medium gap-1 text-xs text-brand-dark-text-description">
            Forgot password?
            <button
              onClick={onPressForgotPassword}
              className="font-semi text-brand-accent hover:underline"
            >
              Reset
            </button>
          </div>
        )}
        {showPassword && (
          <div
            onClick={() => setIsShowingPassword(v => !v)}
            className="text-description-subtle hover:text-brand-light-text-description dark:hover:text-brand-accent select-none cursor-pointer"
          >
            {isShowingPassword ? (
              <span className="">
                {' '}
                <FontAwesomeIcon icon={faEyeSlash} /> Hide
              </span>
            ) : (
              <span>
                {' '}
                <FontAwesomeIcon icon={faEye} /> Show
              </span>
            )}
          </div>
        )}
      </div>

      {textarea ? (
        <textarea
          inputMode={inputMode}
          className={`input min-h-[100px] max-h-[300px] transition-none pt-2`}
          placeholder={placeholder}
          id={label}
          aria-disabled={disabled}
          disabled={disabled}
          {...registeredForm}
        />
      ) : (
        <input
          inputMode={inputMode}
          className={cardStyling ? 'input-card' : 'input'}
          placeholder={placeholder}
          id={label}
          aria-disabled={disabled}
          disabled={disabled}
          type={isSecure && !isShowingPassword ? 'password' : undefined}
          {...registeredForm}
        />
      )}

      {error && (
        <div className="flex items-center gap-1 text-warning">
          {/* <FontAwesomeIcon icon={faExclamation} /> */}
          <span className="">{error.message}</span>
        </div>
      )}
    </div>
  )
}

export default FormInput
